<template>
  <base-dialog
    :visible.sync="visible"
    :title="title"
    @submit="submit"
    width="800px"
    class="add-org-info"
  >
    <el-collapse v-model="activeItem">
      <el-collapse-item title="机构信息" name="orgInfo">
        <base-form
          :formAttrs="{
            model: queryParas,
          }"
          ref="orgForm"
          :showBtns="false"
          :componentList="componentList"
        ></base-form
      ></el-collapse-item>
      <!-- <el-collapse-item title="权限列表" name="perMission">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="签发"></el-checkbox>
          <el-checkbox label="开立"></el-checkbox>
        </el-checkbox-group>
      </el-collapse-item> -->
    </el-collapse>
  </base-dialog>
</template>

<script>
import baseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseForm from '@/components/common/base-form//base-form.vue'
import { orgApi } from '@/api/orgApi'
// import { validateEmojiReg } from '@/pages/my-financing-bill/main.config'

export const validateNumber = (rule, value, cb) => {
  if (!value && typeof value !== 'number') {
    cb()
  }
  if (!/^\d*$/.test(value)) {
    cb(new Error('只能输入整数'))
  } else if (value > 1000 || value < 1) {
    cb(new Error('输入范围1~1000'))
  }
  cb()
}
export default {
  props: {},
  components: {
    baseDialog,
    BaseForm
  },
  data () {
    return {
      editorModel: 'C',
      visible: false,
      activeItem: ['perMission', 'orgInfo'],
      checkList: [],
      queryParas: {},
      perentNode: Object.create(null)
    }
  },
  computed: {
    title () {
      return this.editorModel === 'C' ? '新增机构' : '编辑机构'
    },
    componentList () {
      return [
        {
          label: '上级机构',
          prop: 'parentOrgName',
          attrs: {
            disabled: true
          },
          span: 24
        },
        {
          label: '组织机构名称',
          prop: 'orgName',
          attrs: {
            placeholder: '请输入组织机构名称',
            maxlength: '30'
          },
          rules: [
            { required: true, message: '请输入组织机构名称', trigger: ['change', 'blur'] }
          ],
          span: 24
        },
        {
          label: '序号',
          prop: 'orgSeq',
          attrs: {
            maxlength: 4,
            placeholder: '请输入组织机构序号'
          },
          rules: [{ validator: validateNumber, trigger: ['change', 'blur'] }],
          span: 24,
          on: {
            input: (data) => {
              if (data) {
                const arr = data.split('')
                if (arr[0] === '0') {
                  this.queryParas.orgSeq = ''
                  this.warning('机构序号不能以0开始')
                }
              }
            }
          }
        }
      ]
    },
    isEdit () {
      return this.editorModel === 'E'
    }
  },
  methods: {
    getCompnyCertNo (keyWord) {
      return orgApi.getBussinessInfo({
        keyWord
      })
    },
    companyNameChange () {
      this.queryParas.certNo = ''
    },
    async submit () {
      try {
        await this.$refs.orgForm.validate()
        const { ...rest } = this.queryParas
        const postData = { ...rest }

        // postData.orgDescribe =  '1'
        const apiMethod = this.isEdit ? 'orgUpdate' : 'insertorg'
        orgApi[apiMethod](postData)
          .then((res) => {
            this.success(`${this.isEdit ? '修改' : '新增'}成功`)
            this.$emit('refresh', this.isEdit)
            this.visible = false
          })
          .catch(() => {
            console.log('新增失败')
          })
      } catch (error) {
        this.warning('请正确输入表格信息')
      }
    },
    open (data, type) {
      if (type === 'E') {
        data.orgSeq = data.orgSeq || ''
        this.queryParas = Object.assign({}, data)
        this.perentNode = { ...data }
      } else {
        const empty = {}
        empty.keyId = ''
        empty.enterpriseId = data.enterpriseId
        empty.parentOrgId = data.keyId
        empty.parentOrgName = data.orgName
        empty.orgName = ''
        empty.orgSeq = ''
        empty.orgDescribe = ''
        this.queryParas = Object.assign({}, empty)
        this.perentNode = { ...empty }
      }

      this.editorModel = type
      this.visible = true
      this.registerWatcher()
    },
    registerWatcher () {
      // 清除校验
      this.$nextTick(() => {
        this.$refs.orgForm.clearValidate()
      })
      const watcher = this.$watch('visible', (val) => {
        if (!val) {
          this.perentNode = Object.create(null)
          this.queryParas = Object.create(null)
          // 关闭监听
          watcher()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.add-org-info {
  /deep/ .el-dialog {
    margin-top: 12vh !important;
    height: 50vh !important;
  }
  /deep/.el-dialog__body {
    height: 350px !important;
  }
}
</style>
